//
//  Product Lists
//  _____________________________________________
body.page-with-filter {
    .columns {
        display: flex;
        flex-wrap: nowrap;

        .sidebar {
            order: 1;
            width: 270px;
        }

        .column.main {
            order: 2;
            width: calc(100% - 270px);
            padding-left: $indent__l;
            flex: none;
        }

        @media (max-width: 1200px) {
            .sidebar {
                width: 210px;
            }

            .column.main {
                width: calc(100% - 210px);
                padding-left: $indent__base;
            }
        }

        @media (max-width: 800px) {
            flex-direction: column;

            .sidebar {
                width: 100%;
                order: 2;
            }

            .column.main {
                width: 100%;
                padding-left: 0;
                order: 1;
            }
        }
    }

    .page-title {
        text-transform: uppercase;
    }
}

.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
        }

        &-details {
            padding: 10px;
        }

        &-name {
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
            text-align: center;

            >a {
                color: #333;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 0;
                transition: all 0.3s ease;
                min-height: 36px;
                display: block;

                &:hover {
                    color: $active__color;
                    text-decoration: none;
                }
            }
        }

        &-info {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .photo {
                display: block;
                padding: 5px;
                background: #fff;
                margin: auto;
                border: 1px solid #dcdcdc;

                .product-image-wrapper {
                    padding-bottom: 100% !important;
                    background: #fff;

                    img {
                        transition: all 0.3s linear;
                    }
                }
            }
        }

        .description {
            display: none;
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;

            .action.towishlist {
                min-width: 32px;
                height: 32px;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-label {
            display: none;
        }

        .price-box {
            margin: $indent__s 0 $indent__m;
            text-align: center;

            .price {
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax+.price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }

        &:hover {
            .product-image-wrapper {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.column.main {
    .product {
        &-items {
            margin: 0 -10px;
            display: flex;
            flex-wrap: wrap;
        }

        &-item {
            width: 33.33%;
            padding: 0 10px;
            margin-bottom: $indent__base;

            @media (max-width: 992px) {
                width: 50%;
            }

            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }

}

.price-container {

    .price-including-tax+.price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax+.price-excluding-tax,
    .weee,
    .price-including-tax+.price-excluding-tax .price,
    .weee .price,
    .weee+.price-excluding-tax:before,
    .weee+.price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        +.price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

@media (min-width: 1024px) {
    .column.main .products-list {
        .product-item {
            width: 100%;

            .product-item-info {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
            }

            .product-item-details {
                display: flex;
                align-items: flex-start;
                padding: 0;

                .product-item-name {
                    text-align: left;
                }
            }

            .description {
                display: block;
            }

            .product-item-texts {
                padding: 0 $indent__base;
                flex: 2;
            }

            .product-item-inner {
                padding: 0 $indent__base $indent__base $indent__base;
                flex: 1;
                border-left: 1px solid #eee;
            }

        }
    }
}

.column.main .products-grid {
    .product-item {}
}
