.multicheckout {
    .title {
        margin-bottom: $indent__l;

        strong {
            font-weight: $font-weight__regular;
        }
    }

    .table-wrapper {
        margin-bottom: 0;

        .action.delete {
            display: inline-block;
        }

        .col {
            .qty {
                display: inline-block;

                .input-text {
                    @extend .abs-input-qty;
                }
            }

            .label {
                @extend .abs-visually-hidden;
            }

            &.item {
                .action.edit {
                    font-weight: $font-weight__regular;
                    margin-left: $indent__s;
                }
            }
        }
    }

    &:not(.address) {
        .table-wrapper {
            .product-item-name {
                margin: 0;
            }
        }
    }

    > .actions-toolbar {
        margin-top: $indent__xl;
    }

    .actions-toolbar {
        > .secondary {
            display: block;

            .action {
                margin-bottom: $indent__m;

                &.back {
                    display: block;
                    margin-left: 0;
                }
            }
        }

        > .primary {
            margin-right: $indent__s;
        }
    }

    .action.primary {
        @extend .abs-button-l;
    }

    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        margin: $indent__s 0 0;
    }

    @extend .abs-account-blocks;

    .block {
        @extend .abs-add-clearfix;

        .methods-shipping {
            .item-content {
                .fieldset {
                    > .legend {
                        @extend .abs-visually-hidden;
                    }

                    > .legend + br {
                        @extend .abs-no-display;
                    }

                    > .field {
                        &:before {
                            display: none;
                        }

                        .control {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .block-title,
    .block-content .title {
        border-bottom: $border-width__base solid $border-color__base;
        padding-bottom: $indent__s;

        strong {
            font-weight: $font-weight__regular;

            span {
                @include lib-css(color, $primary__color__light);
            }
        }
    }

    .block-content {
        @extend .abs-add-clearfix;
        .title {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &.order-review {
        .block-title > strong {
            @include lib-font-size(24);
        }

        .block-shipping {
            .block-content:not(:last-child) {
                margin-bottom: $indent__xl;
            }
        }
    }

    .box-title {
        span {
            margin-right: $indent__s;
        }

        > .action {
            margin: 0;
        }
    }

    .box-shipping-method {
        .price {
            font-weight: $font-weight__bold;
        }
    }

    .box-billing-method {
        .fieldset {
            margin: 0;

            .legend.box-title {
                margin: 0 0 $indent__xs;
            }
        }
    }

    .hidden {
        @extend .abs-no-display;
    }

    .checkout-review .grand.totals {
        @include lib-font-size($font-size__xl);
        margin-bottom: $indent__xl;

        .mark {
            font-weight: $font-weight__regular;
        }
    }
}

[class^='multishipping-'] {
    .nav-sections,
    .nav-toggle {
        @extend .abs-no-display;
    }

    .logo {
        margin-left: 0;
    }
}

.multishipping-checkout-success {
    .nav-sections {
        display: block;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .multicheckout {
        .data.table {
            .address {
                &:before {
                    margin-bottom: $indent__xs;
                }
            }
        }

        .product-item-name,
        .price-including-tax,
        .price-excluding-tax {
            display: inline-block;
        }

        .block-content .box {
            &:not(:last-child) {
                margin-bottom: $indent__xl;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.order-review {
            .box-items {
                .data.table {
                    thead {
                        display: block;

                        tr {
                            display: block;
                        }

                        .col.item {
                            display: block;
                            padding: 0;
                        }
                    }
                }
            }

            .data.table {
                @extend .abs-checkout-order-review;
            }
        }

        .actions-toolbar {
            .action {
                margin-bottom: $indent__m;
            }

            > .primary {
                margin-bottom: $indent__m;
                margin-right: 0;
            }
        }
    }
}

@include min-screen($screen__s) {
    .multicheckout {
        .actions-toolbar {
            .secondary {
                float: none;
                margin-top: 11px;
                text-align: right;

                .action {
                    margin-left: $indent__s;

                    &.back {
                        display: block;
                        float: left;
                    }
                }
            }
        }

        .item-options {
            margin: $indent__base 0 0;
        }

        .block-content .box {
            margin-bottom: 0;
        }

        .block-shipping {
            .box {
                @extend .abs-add-box-sizing-desktop-s;
                float: left;
                width: 25%;
            }

            .box-shipping-method {
                padding-left: $indent__m;
                padding-right: $indent__m;
                width: 50%;

                .fieldset {
                    .legend {
                        @extend .abs-reset-left-margin-desktop-s;
                    }

                    .field {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .block-billing {
            @extend .abs-add-clearfix-desktop-s;
            .box-billing-address {
                @extend .abs-add-box-sizing-desktop-s;
                float: left;
                width: 25%;
            }

            .box-billing-method {
                @extend .abs-add-box-sizing-desktop-s;
                float: left;
                padding-left: $indent__m;
                width: 50%;
            }
        }

        &.form.address {
            .table-wrapper {
                .applicable {
                    margin: 7px 0 0;
                }
            }
        }

        &.order-review {
            .box-items {
                clear: left;
                float: none;
                padding-top: $indent__xl;
                width: auto;
            }

            .col.item {
                width: 75%;
            }
        }

        //  Payment methods
        .methods-payment {
            .item-content > .fieldset {
                width: auto;

                .field {
                    &.cvv {
                        display: inline-block;
                        width: auto;
                    }
                }
            }

            .fieldset > .field:not(.choice) {
                > .label {
                    float: none;
                    margin-bottom: 8px;
                    text-align: left;
                    width: auto;
                }

                &:not(.cvv) {
                    .control {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .multishipping-checkout-success {
        .nav-toggle {
            display: block;
        }

        .logo {
            margin-left: $indent__xl;
        }
    }
}
