//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background : $color-yellow-light2 !default;
$message-global-note__border-color : $color-yellow-light3 !default;
$message-global-note__color : $text__color !default;

$message-global-note-link__color : $link__color !default;
$message-global-note-link__color-hover : $link__hover__color !default;
$message-global-note-link__color-active : $link__active__color !default;

$message-global-caution__background : $color-red9 !default;
$message-global-caution__border-color : none !default;
$message-global-caution__color : $color-white !default;

$message-global-caution-link__color : $link__color !default;
$message-global-caution-link__color-hover : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color : false !default;
$header-icons-color : $color-gray56 !default;
$header-icons-color-hover : $color-gray20 !default;

$addto-color : $text__color__muted !default;
$addto-hover-color : $primary__color !default;

$minicart-icons-color : $header-icons-color !default;
$minicart-icons-color-hover : $header-icons-color-hover !default;

$button__shadow : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop : $indent__base !default;

//  Footer
$footer__background-color : $primary__color !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.header.content {
    height: 70px;
    display: flex;
    align-items: center;

    .nav-toggle {
        order: 5;
    }

    .logo {
        order: 2;
    }

    .sections.nav-sections {
        order: 3;
    }

    .block-search {
        order: 4;
    }


}

.logo {
    margin: 0;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }

    @media (max-width: 1200px) {
        width: 170px;
    }
}



.customer-links {

    .account-links {
        position: relative;
        height: 40px;
        display: flex;
        align-items: center;

        a {
            color: $text__color;
        }
    }

    .header-link {
        #header-account-menu {
            display: none;
            position: absolute;
            right: 0;
            top: 40px;
            flex-direction: column;
            background: #f6f5f6;
            padding: 10px;
            margin: 0;
            list-style: none;

            li {
                margin: 0;

                +li {
                    margin-top: 5px;
                }
            }

            a {
                display: block;
                color: $text__color;
                padding: 5px 10px;
                white-space: nowrap;

                &:hover {
                    text-decoration: underline;
                }
            }

            .logout {
                border-top: 1px solid #eee;
                padding-top: 5px;
            }

            .logout a {
                text-align: center;
            }
        }

        @media (max-width: 600px) {
            width: 110px;

            #header-account-menu { 
                right: initial;
                left: 0;
            }
        }
    }
}

body.account-menu-open .customer-links .header-link #header-account-menu {
    display: flex;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

body.account-menu-open .customer-links .header-link>a i {
    transform: rotate(-180deg);
}

.page-main {
    >.page-title-wrapper {
        .page-title+.action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, #f6f5f6);
    margin-top: auto;
}

.footer {
    color: $text__color;

    a {
        color: inherit;
    }

    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            >li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background: #fff;
    z-index: 10;
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {

    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

.page-header .header.panel {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 10;

    #contact-link {
        color: $text__color;

        a {
            color: $text__color;

            &:hover {
                text-decoration: none;
                color: $active__color;
            }
        }

        strong {
            color: $active__color;
            font-weight: normal;
        }

        span+span {
            margin-left: 15px;
        }
    }

    .customer-links {
        display: flex;
        align-items: center;
    }

    @media (max-width: 860px) {
        span span {
            display: none;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
        height: 52px;
        padding: 0;
        box-shadow: 1px 1px 1px rgba(0,0,0,0.1);

        #contact-link {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .customer-links {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: #fff;
            padding: 0 5px 0 15px;
        }
    }
}

.page-wrapper {
    padding-top: 110px;
}

.page-header {
    border: 0;
    margin-bottom: 0;

    .panel.wrapper {
        border-bottom: 0;
        background: #f6f5f6;
        color: $text__color;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        >.breadcrumbs,
        >.top-container,
        >.widget {
            box-sizing: border-box;
            width: 100%;
        }

        .breadcrumbs {
            padding: 30px 20px 0px 20px;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            >.header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                >li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }
    }

    

    .page-main {
        >.page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title+.action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
