.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        margin-bottom: $indent__base;

        strong {
            font-size: 18px;
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        font-size: 16px;
        margin: 0 0 $indent__base;
        word-break: break-all;
        color: #242424;
        background: #f6f5f6;
        padding: 10px 15px;
    }

    .filter-options-content {
        margin: 0 0 $indent__m;
        padding: 0 15px;
        padding-bottom: $indent__m;
        border-bottom: 1px solid #eee;

        .item {
            margin-bottom: 5px;

            a {
                font-weight: normal;
                color: $text__color;
            }
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

#active-filters {
    margin-bottom: $indent__base;

    .filter-current-subtitle {
        font-size: 16px;
        margin: 0 0 $indent__s;
        word-break: break-all;
        color: #242424;
        font-weight: bold;
    }
}

.amshopby-filter-name {
    white-space: nowrap;
    &::after {
        white-space: nowrap;
        content: ":";
        margin-right: 5px;
    }
}

.items {
    @extend .abs-reset-list;
}

.amshopby-slider-container .ui-slider-range {
    background: #ececec;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}
