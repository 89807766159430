$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 0;
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    display: flex;
    padding-bottom: $indent__s;
    border-bottom: 1px solid #eee;

    .toolbar-amount,
    .pages {
        display: none;
    }

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;

        > * {
            margin-left: 0 !important;
            margin-right: 0 !important;

            + * {
                margin-top: 10px;
            }
        }
    }
}

.toolbar-products ~ .toolbar-products {
    padding-top: $indent__s;
    border-top: 1px solid #eee;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: $indent__xl;

    .limiter,
    .sorter,
    .modes {
        display: none !important;
    }

    .pages {
        display: flex;
        margin: auto;
        order: 10;
    }
    .toolbar-amount {
        display: block;
    }
}

.sorter {
    padding: 0;

    label {
        color: #333;
        font-weight: bold;
    }
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font($icon-arrow-up,
        $_icon-font-size : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide : true);
}

.sorter {
    .sort-desc {
        &:before {
            content: $icon-arrow-down;
        }
    }
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}


.page-products {
    .columns {
        padding-top: 0;
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    .products.wrapper~& .pages {
        float: left;
    }
}

.toolbar-amount {
    float: left;
}

.sorter {
    float: right;
}

.modes {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;

    .modes-label {
        color: #333;
        margin-right: $indent__xs;
    }

    @media (max-width: 1025px) {
        display: none !important;
    }
}

.modes-mode {

    &,
    &:visited,
    &:active {
        color: #eee;
    }

    &,
    &:hover {
        text-decoration: none;
    }

    font-size: 24px;

    &.active {
        color: #333;
    }

    + .modes-mode {
        margin-left: 5px;
    }
}


.limiter {
    display: flex;
    align-items: center;
    margin-left: $indent__s;

    label {
        color: #333;
        font-weight: bold;
    }
}
