/* Theme adjustments */
body {
    font-family: $font-family__base;
}

body.account,
body.customer-account-forgotpassword,
body.customer-account-login,
body.customer-account-create,
body.customer-account-confirmation,
body.customer-account-createpassword,
body.customer-account-logoutsuccess {
    .page-title-wrapper {
        border-bottom: 1px solid #dcdcdc;
        margin-bottom: $indent__l;
    }
}

.global-seo-content {
    margin-bottom: $indent__xl;

    >.title {
        font-size: 20px;
        display: block;
        margin-bottom: 15px;
    }
}

div.footer.content {
    padding-bottom: 0;
}

div.footer-infos {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    padding: 30px;
    box-shadow: 0px -3px 4px #f3f3f3;
    
    .footer-block {
        width: 33.33%;

        >.title {
            text-transform: uppercase;
            font-weight: 700;
            display: block;
            margin-bottom: 10px;
        }

        ul {
            padding-right: $indent__base;

            li {
                font-size: 12px;

                +li {
                    margin-top: 10px;
                }
            }
        }
    }

    #block-social-links {
        display: flex;
        align-items: center;
        margin-top: $indent__base;
        padding-top: $indent__base;
        border-top: 1px solid $text__color;
        max-width: calc(100% - 30px);

        strong {
            display: block;
            margin-right: $indent__xl;
            text-transform: uppercase;
        }
    }

    #block-social-links {

        ul {
            display: flex;

            li {
                font-size: 18px;
            }

            li+li {
                margin: 0;
                margin-left: 15px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;

        .footer-block {
            width: 100%;

            + .footer-block {
                margin-top: 30px;
            }
        }
    }
}

#block-contact-infos {
    color: $text__color;

    i {
        color: inherit;
        display: inline-block;
        margin-right: $indent__s;
    }

    span {
        text-transform: uppercase;
        color: inherit;
    }

    strong {
        color: inherit;
    }
}

.ampromo-overlay {
    display: none;
}

.home-content {
    margin-bottom: 30px;
}

.home-blocks {
    display: flex;
    margin: 0 -15px;
    width: calc(100% + 30px);
    margin-bottom: 30px;

    .home-block {
        position: relative;
        width: 50%;
        margin: 15px;
        min-height: 100px;
        background: #eee;

        img {
            display: block;
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            padding: $indent__base;
            justify-content: center;
            align-items: flex-start;
            background: rgba(255, 255, 255, 0.2);
            transition: 0.2s all ease-out;

            span {
                display: block;
                padding: 10px 20px;
                text-transform: uppercase;
                color: #fff;
                font-weight: bold;
                background: #232323;
            }

            &:hover {
                text-decoration: none;
                background: rgba(255, 255, 255, 0.4);
                transition: 0.2s all ease-out;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
        width: 100%;
        margin-bottom: 30px;

        .home-block {
            margin: 0;
            width: 100%;

            + .home-block {
                margin-top: 15px;
            }
        }
    }
}

.home-socials {
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 20px;
    margin-bottom: 0;

    strong.title {
        font-size: 20px;
        margin-bottom: 0;
        margin-right: 30px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        li {
            margin: 5px;
            font-size: 20px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                background: $active__color;
                border-radius: 100%;
                width: 40px;
                height: 40px;

                color: #fff;

                &:hover {
                    background: lighten($active__color, 10%);
                    text-decoration: none;
                }
            }
        }

    }
}

.reassurance {
    display: flex;
    padding: 0;
    background: linear-gradient(45deg, $active__color, darken($active__color, 10%));

    .container {
        display: flex;
        margin: auto;

        @media (max-width: 970px) {
            flex-wrap: wrap;
        }
    }

    .block-footer {
        margin: 0;
        flex: 1;
        min-width: 160px;

        >a {
            display: flex;
            justify-content: center;
            color: #fff;
            flex-wrap: wrap;
            padding: 30px 15px;
            height: 100%;
            align-items: flex-start;
            transition: 0.2s all ease-out;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-direction: column;
            }

            img {
                max-width: 40px;
                margin-bottom: 15px;
            }

            h3 {
                margin: 0;
                display: inline-block;
                text-align: center;
                font-size: 16px;
            }

            &:hover {
                text-decoration: none;
                transform: scale(1.05);
                background: $active__color;
                transition: 0.2s all ease-out;
                box-shadow: 1px 1px 10px rgba(0,0,0,0.05);
            }

            &:active {
                transform: scale(1.05) translateY(-5px);
                transition: 0.2s all ease-out;
            }

            p {
                width: 100%;
                text-align: center;
                margin-top: 10px;
                font-size: 11px;
                margin-bottom: 0;
            }
        }
    }

    
}


/** CATEGORY FILTER TREE **/
.am-filter-items-attr_category_ids > li[data-label="Femmes"] {
    display: none !important;
}
.am-filter-items-attr_category_ids > li[data-label="Hommes"] {
    display: none !important;
}

body[class*="categorypath-chaussure-femme"] .am-filter-items-attr_category_ids {
    > li[data-label="Femmes"] {
        display: block !important;
    }
    > li[data-label="Pointures"] {
        display: none !important;
    }
}
body[class*="categorypath-chaussures-homme"] .am-filter-items-attr_category_ids {
    > li[data-label="Hommes"] {
        display: block !important;
    }
    > li[data-label="Pointures"] {
        display: none !important;
    }
}

/** HIDE REGION FIELD ON CHECKOUT **/

.field[name="shippingAddress.region_id"] {
    display: none !important;
}
.field[name="shippingAddress.street.0"] > .label {
    display: none !important;
}


/** HIDE TAX IN TOTALS **/
tr.totals-tax,
tr.grand.totals.excl {
    display: none !important;
}

/** Updates mini cart **/
.block-minicart .block-content > .actions{
    margin-top: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9;

    .secondary{
        a{
           text-align: center;
           width: calc(100% + 42px);
           margin-left: -21px;
        }
    }
}

.minicart-wrapper.active .block-minicart{
    overflow: hidden;
}

.minicart-wrapper .block-minicart{
    max-height: 500px;
}

.minicart-items-wrapper{
    max-height: 500px;
    padding-bottom: 60px !important;
}

.payment-method-icon {
    .payment-method-bank-icon.payment_method_monetico_desjardins {
        display: none !important;
    }
}