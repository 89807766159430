.block-header-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: linear-gradient(45deg, #FE921A, #e47801);
    padding: 20px 0px;

    strong {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.6rem;
        text-transform: uppercase;
        color: white;
    }

    span {
        font-size: 20px;
        margin-top: 15px;
        color: white;
    }
}