//  Newsletter subscription
.footer-infos div.block.newsletter {
    margin-bottom: 0;
    float: none;
    width: 100%;

    .title {
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
    }

    .label {
        display: none;
    }

    form {
        display: flex;
        width: 100%;

        .field.newsletter {
            width: calc(100% - 32px);
        }

        input,
        button {
            height: 32px;
            border-radius: 0;
        }

        input {
            background: #fff;
            border: none;
            color: #888;
            display: inline-block;
            font-size: 12px;
            border: 0;
        }

        button {
            font-weight: normal;
            padding: 0;
            width: 32px;
            font-size: 11px;
        }
    }
}
