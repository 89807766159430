.nav-sections {
    .row {
        margin: 0 -15px;

        &:before {
            content: "";
            display: table;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        float: left;
        margin-bottom: 15px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-11 {
        width: 91.66666667%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    ul,
    ol {
        list-style: none;
        margin: 0;
    }

    .label {
        color: #333333;
    }

    .fieldset {
        margin-bottom: 25px;
    }

    .block {
        .price-box {
            float: left;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;

            >p {
                float: left;
                margin-top: 5px;
            }

            button.action {
                margin-left: 20px;
                float: left;
                text-transform: uppercase;
            }
        }
    }

    .parentMenu {
        a {
            display: block;
        }
    }

    .explodedmenu-menu-popup {
        h3 {
            font-weight: normal;
        }
    }

    @media (max-width: 991px) {
        .block {
            .price-box {
                margin-top: 5px;
                width: 100%;

                button.action {
                    margin-left: 0;
                }
            }
        }
    }

    .nav-sections-items,
    .nav-sections-item-content {
        width: 100%;
    }

    .nav-magemenu-menu,
    .nav-magemenu-menu *,
    .nav-magemenu-menu *:before,
    .nav-magemenu-menu *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .nav-magemenu-menu {
        position: relative;
        z-index: 8;
        clear: both;

        &:hover {
            z-index: 9;
        }
    }

    .sections.nav-sections {
        .navigation.nav-magemenu-menu {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    ul.itemsubmenu {
        visibility: hidden;
        filter: alpha(opacity=0);
        -webkit-opacity: 0;
        -moz-opacity: 0;
        -ms-opacity: 0;
        -o-opacity: 0;
        opacity: 0;
        background: none;
        width: 215px;
        margin-left: 100%;
        padding: 5%;
    }

    .default-list,
    .submenu-container.grid {
        >ul.itemsubmenu {
            background: #fff;
        }
    }

    .nav-magemenu-menu {
        ul {
            margin: 0;
            padding: 0;

            &:before {
                content: "";
                display: table;
            }

            &:after {
                content: "";
                display: table;
                clear: both;
            }

            &.itemsubmenu {
                margin: 0;
                padding: 0;
            }
        }

        li {
            padding: 0;
            margin: 0;
        }

        a {
            text-decoration: none;
            color: #333;
        }

        .mega-icon {
            margin-right: 5px;
            cursor: pointer;
        }

        .mega-label {
            position: absolute;
            font-size: 9px;
            padding: 0 6px;
            background-color: #72BF20;
            color: #fff !important;
            line-height: 15px;
            margin: 0 5px;

            &:before {
                border-color: #72BF20 transparent transparent;
                border-style: solid;
                border-width: 4px;
                content: "";
                height: 0;
                position: absolute;
                left: 8px;
                top: 15px;
                -webkit-transition: border-color 450ms ease-in-out 0s;
                -moz-transition: border-color 450ms ease-in-out 0s;
                -ms-transition: border-color 450ms ease-in-out 0s;
                -o-transition: border-color 450ms ease-in-out 0s;
                transition: border-color 450ms ease-in-out 0s;
                width: 0;
            }

            &.hot {
                background-color: #FAD160;

                &:before {
                    border-color: #FAD160 transparent transparent;
                }
            }
        }

        .ui-menu {
            font-weight: normal;

            >li {
                float: left;
                display: block;

                >a {
                    display: inline-block;
                    position: relative;
                    padding: 0 12px;
                    line-height: 50px;
                    text-transform: uppercase;
                    font-weight: bold;

                    .mega-label {
                        top: -10px;
                        right: 15px;
                    }
                }

                >.submenu {
                    border-top: 2px solid #fad160;

                    .submenu {
                        left: 100%;
                        top: 0;
                        margin-left: -20px;
                    }
                }

                >.ui-menu-icon {
                    display: none;
                }
            }

            li {

                &.megamenu {
                    position: static;
                }

                &:hover {
                    >.submenu {
                        filter: alpha(opacity=100);
                        -moz-opacity: 1;
                        opacity: 1;
                        visibility: visible;
                        display: block;
                    }
                }
            }

            .ui-menu-icon {
                width: 20px;
                line-height: 26px;
                height: 30px;
                position: absolute;
                right: 0;
                text-align: center;
                font-size: 16px;
                cursor: pointer;

                &:before {
                    content: "\f105";
                    font-family: 'FontAwesome';
                }
            }
        }

        .submenu {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 100;
            min-width: 280px;
            padding: 12px;
            background-color: #fff;
            -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

            a {
                line-height: 30px;
                display: inline-block;
            }
        }

        .megamenu {
            >.submenu {
                left: 0;
                right: 0;

                .submenu {
                    position: static;
                    padding: 0 !important;
                    margin: 0 !important;
                    display: block !important;
                    visibility: visible;
                    filter: alpha(opacity=100);
                    -moz-opacity: 1;
                    opacity: 1;
                    -webkit-box-shadow: none !important;
                    -moz-box-shadow: none !important;
                    box-shadow: none !important;
                }

                .parent>a {
                    font-weight: bold;
                }

                .ui-menu-icon {
                    display: none;
                }
            }
        }
    }

    .nav-magemenu-menu {

        >.horizontal-menu>ul>li {
            +li {
                margin-left: 30px;
            }

            >a {
                text-transform: uppercase;
                display: block;
                padding: 2px 1px;
                position: relative;
                font-size: 15px;
                line-height: 22px;
                font-family: $font-family-name__secondary;
                font-weight: 400;

                &::after {
                    background: $active__color;
                    content: "";
                    height: 2px;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 100%;
                    -webkit-transform: translateY(10px);
                    transform: translateY(10px);
                    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
                    transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
                    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
                    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
                    width: 100%;
                }
            }

            &.active,
            &:hover {
                >a {
                    color: $active__color;

                    &::after {
                        opacity: 1;
                        -webkit-transform: translateY(0px);
                        transform: translateY(0px);
                    }
                }
            }
        }
    }

    .magemenu-menu {
        img {
            max-width: 100%;
        }

        .menu {
            >div {
                position: absolute;
                z-index: 99;
                top: auto;
                width: 100%;
                left: 0;
                visibility: hidden;
                padding-top: 2px;
                height: 0;
                overflow: hidden;
            }
        }

        li.menu {
            >div>div.menu-box {
                position: relative;
                visibility: hidden;
                filter: alpha(opacity=0);
                -webkit-opacity: 0;
                -moz-opacity: 0;
                -ms-opacity: 0;
                -o-opacity: 0;
                opacity: 0;
                width: 100%;
                background: #fff;
                -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                padding: 15px 0;

                .block-main {
                    margin-bottom: 0;
                }
            }
        }
    }

    /** Effect **/

    .magemenu-menu {
        li.menu {
            >div {
                >div.menu-box {
                    &.btt {
                        margin-top: 25px;
                    }

                    &.ttb {
                        margin-top: -25px;
                    }

                    &.rtl {
                        position: absolute;
                        right: -25px;
                    }

                    &.ltr {
                        position: absolute;
                        left: -25px;
                    }

                    &.z {
                        -webkit-transform: scale(0.8);
                        -moz-transform: scale(0.8);
                        -ms-transform: scale(0.8);
                        -o-transform: scale(0.8);
                        transform: scale(0.8);
                    }
                }
            }

            &.active,
            &:hover {
                >div {
                    height: auto;
                    overflow: visible;

                    >div.menu-box {
                        &.btt {
                            margin-top: 0;
                        }

                        &.ttb {
                            margin-top: 0;
                        }

                        &.rtl {
                            position: absolute;
                            right: 0;
                        }

                        &.ltr {
                            position: absolute;
                            left: 0;
                        }

                        &.z {
                            -webkit-transition: all 0.5s ease-out 0s;
                            -moz-transition: all 0.5s ease-out 0s;
                            -o-transition: all 0.5s ease-out 0s;
                            transition: all 0.5s ease-out 0s;
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }

    /** End effect **/

    .menu.parentMenu {
        div.block-main {
            >ul.itemsubmenu {
                margin-left: 0;
            }
        }
    }

    .magemenu-menu {
        li.menu {
            >div {
                >div.menu-box {
                    * {
                        max-width: 100%;
                    }

                    &:after {
                        content: " ";
                        display: block;
                        clear: both;
                    }
                }
            }

            &.active,
            &:hover {
                >div {
                    >div.menu-box {
                        visibility: visible;
                        filter: alpha(opacity=100);
                        opacity: 1;
                        -webkit-opacity: 1;
                        -moz-opacity: 1;
                        -ms-opacity: 1;
                        -o-opacity: 1;
                        -webkit-transition: all 0.3s ease-out 0s;
                        -moz-transition: all 0.3s ease-out 0s;
                        -o-transition: all 0.3s ease-out 0s;
                        transition: all 0.3s ease-out 0s;
                    }
                }
            }
        }

        li {
            &.active,
            &:hover {

                >ul.itemsubmenu,
                div.block-main>ul.itemsubmenu {
                    visibility: visible;
                    position: unset;
                    filter: alpha(opacity=100);
                    -webkit-opacity: 1;
                    -moz-opacity: 1;
                    -ms-opacity: 1;
                    -o-opacity: 1;
                    opacity: 1;
                    margin-top: 0;
                    -webkit-transition: all 0.3s ease-out 0s;
                    -moz-transition: all 0.3s ease-out 0s;
                    -o-transition: all 0.3s ease-out 0s;
                    transition: all 0.3s ease-out 0s;
                }
            }
        }

        ul.itemsubmenu {
            li {
                width: 100%;
                position: relative;
                margin: 0;

                a {
                    display: block;
                    position: relative;
                    color: $active__color;
                    width: 100%;
                    font-weight: normal;
                    font-size: 14px;
                }

                >.submenu-container {
                    ul {
                        opacity: 1;
                        visibility: visible;
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;

                        li+li {
                            margin-top: 5px;
                        }

                        li a {
                            color: #333;
                        }
                    }
                }
            }
        }
    }

    /*--Grid--*/

    .magemenu-menu {
        .block-main.grid {
            .grid-child {
                >ul {
                    display: inline-block;
                    width: 100%;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    background: none;

                    >li {
                        border: none;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        li {
            div.block-main {
                .grid-child {
                    >ul.itemsubmenu {
                        margin-left: 0;
                    }
                }
            }

            &.active,
            &:hover {
                div.block-main {
                    .grid-child {
                        >ul.itemsubmenu {
                            visibility: visible;
                            filter: alpha(opacity=100);
                            -webkit-opacity: 1;
                            -moz-opacity: 1;
                            -ms-opacity: 1;
                            -o-opacity: 1;
                            opacity: 1;
                            margin-top: 0;
                            margin-left: 0;
                            -webkit-transition: all 0.3s ease-out 0s;
                            -moz-transition: all 0.3s ease-out 0s;
                            -o-transition: all 0.3s ease-out 0s;
                            transition: all 0.3s ease-out 0s;
                        }
                    }
                }
            }
        }
    }

    /*--End Grid--*/
    /*--Tabs--*/
    ul.itemsubmenu.subtabs {
        width: 100%;
        position: relative !important;

        >li.itemMenu {
            position: static !important;
            width: 215px;
            float: left;
            clear: both;

            .tab-menu-content {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                width: calc(100% - 215px);
                padding: 15px;
                float: right;
                display: none;

                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }

            &.active {
                .tab-menu-content {
                    display: block;
                    min-height: 100%;
                }
            }
        }

        .magemenu-menu {
            li {
                &.active,
                &:hover {

                    >ul.itemsubmenu.subtabs,
                    div.block-main>ul.itemsubmenu.subtabs {
                        -webkit-transition: opacity, margin 0.3s ease-out 0s;
                        -moz-transition: opacity, margin 0.3s ease-out 0s;
                        -o-transition: opacity, margin 0.3s ease-out 0s;
                        transition: opacity, margin 0.3s ease-out 0s;
                    }
                }
            }

            ul.itemsubmenu.subtabs {
                >li.itemMenu {
                    &.active {}
                }
            }
        }

        /*--End Tabs--*/
        /*--------Horizontal--------*/

        .magemenu-menu {


            &.horizontal-menu {
                color: #333;
                font-style: normal;
                font-weight: 400;
                line-height: 1.42857143;
                font-size: 1.4rem;
                clear: both;
                display: block;

                img {
                    height: auto;
                }

                .fieldset {

                    >.field,
                    >.fields>.field {
                        margin: 0 0 20px;

                        >.label {
                            margin: 0 0 8px;
                            display: inline-block;
                        }
                    }
                }

                .label {
                    color: #333333;
                }

                .menu {
                    >a {
                        position: relative;

                        img {
                            position: absolute;
                            left: 50%;
                            margin-left: -16px;
                            top: -12px;
                        }
                    }
                }

                .explodedmenu {
                    width: 100%;
                    position: relative;
                    padding: 0;

                    >li {
                        padding: 5px 0;
                    }

                    li.menu {
                        float: left;

                        >a {
                            display: block;
                            position: relative;
                            padding: 8px 20px;
                            margin: 3px 1px 3px 0;

                            span {
                                font-weight: 500 !important;
                            }
                        }

                        &.parentMenu {
                            .explodedmenu-menu-popup {
                                position: absolute;
                                top: 58px;
                                z-index: 991;
                                filter: alpha(opacity=0);
                                -moz-opacity: 0;
                                opacity: 0;
                                visibility: hidden;
                                left: 0;
                            }

                            &:hover {
                                .explodedmenu-menu-popup {
                                    top: 38px;
                                    filter: alpha(opacity=100);
                                    -moz-opacity: 1;
                                    opacity: 1;
                                    visibility: visible;
                                    -webkit-transition: all 0.3s ease-out 0s;
                                    -moz-transition: all 0.3s ease-out 0s;
                                    -o-transition: all 0.3s ease-out 0s;
                                    transition: all 0.3s ease-out 0s;
                                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                                    background: #fff;
                                    -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                                    -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                                    max-width: 1200px;
                                }
                            }
                        }
                    }
                }

                .subparent {
                    &:before {
                        /*content: "\f105";*/
                        font-family: "FontAwesome";
                        font-size: 14px;
                        top: 7px;
                        right: 10px;
                        color: #444;
                        position: absolute;
                        -webkit-transition: all 0.3s ease-out 0s;
                        -moz-transition: all 0.3s ease-out 0s;
                        -o-transition: all 0.3s ease-out 0s;
                        transition: all 0.3s ease-out 0s;
                    }
                }
            }
        }
    }

    .magemenu-menu {
        &.horizontal-menu {
            >ul {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .explodedmenu {
                >li:hover>a {
                    -webkit-transition: all 0.5s ease-out 0s;
                    -moz-transition: all 0.5s ease-out 0s;
                    -o-transition: all 0.5s ease-out 0s;
                    transition: all 0.5s ease-out 0s;
                    z-index: 1;
                }
            }

            .subparent:hover:before {
                right: 5px;
                -webkit-transition: all 0.3s ease-out 0s;
                -moz-transition: all 0.3s ease-out 0s;
                -o-transition: all 0.3s ease-out 0s;
                transition: all 0.3s ease-out 0s;
                color: #fff;
            }
        }
    }

    @media (max-width: 767px) {
        .magemenu-menu {
            &.horizontal-menu {
                .explodedmenu {
                    li.menu>a {
                        padding: 8px 15px;
                    }
                }
            }
        }
    }

    /*--------End Horizontal--------*/

    /*
  *** Default list (original)
  */
    .magemenu-menu {
        li.menu.original {
            >div {
                width: auto;
                left: auto;

                >div.menu-box {
                    padding: 0;
                }
            }

            .default-list {
                margin: 0;
                padding: 0;
            }

        }
    }

    /*
  *** Menu Label
  */
    .label-preview {
        background-color: #f0f0f0;
        text-align: center;
        padding: 5px 0;
        margin: 0 0 3rem;
    }

    .page-main-actions._hidden~.page-columns .label-preview {
        left: 8.800000000000001rem;
        position: fixed;
        right: 0;
        top: 77px;
        z-index: 501;
    }

    .label-preview {
        .menu-item {
            position: relative;
            display: inline-block;
            margin: 3px auto;
            padding: 8px 20px;
            cursor: pointer;
            -webkit-transition: all 0.5s ease-out 0s;
            -moz-transition: all 0.5s ease-out 0s;
            -o-transition: all 0.5s ease-out 0s;
            transition: all 0.5s ease-out 0s;
        }
    }


    .nav-magemenu-menu {
        .ui-menu {
            li {
                padding: 0;

                &:hover {
                    background-color: #F0F0F0;
                }

                .submenu {
                    position: static;
                    padding: 0;
                    margin: 0 !important;
                    display: none !important;

                    &:first-child {
                        display: none;
                    }
                }
            }

            >li {
                float: none;
                display: block;
                border-bottom: 1px solid #ccc;

                .ui-menu-icon {
                    display: block;
                    width: auto;
                    top: 0;
                    right: 0;
                    left: 0;
                    cursor: pointer;
                    text-align: right !important;
                    padding-right: 15px;
                    line-height: 38px;
                    height: 38px;

                    &.has-active {
                        color: #fad160;

                        &:before {
                            content: "\f106";
                        }
                    }
                }

                >.ui-menu-icon {
                    height: 50px;
                    line-height: 50px;
                    text-align: right !important;
                }

                a {
                    position: relative;
                    z-index: 10;
                    display: inline-block !important;
                }
            }

            .parent {
                >a {
                    font-weight: bold;
                }
            }

            .has-active {
                background-color: #F0F0F0;

                >a {
                    color: #fad160;
                }

                >.submenu {
                    display: block !important;
                }
            }
        }
    }

    /*
  *** Common
  */
    @media (max-width: 767px) {

        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12 {
            padding: 0;
            margin: 0;
            width: 100%;
        }

        background: #fff;

        .section-item-title {
            display: none !important;
        }

        .magemenu-menu.horizontal-menu>ul {
            flex-direction: column;
            padding-top: 30px;

            >li {
                width: 100%;
            }

            >li+li {
                margin-left: 0;
            }
        }

        .sections.nav-sections {
            z-index: 99;
        }

        .nav-magemenu-menu {
            z-index: auto;

            &:hover {
                z-index: auto;
            }

            a,
            a:hover {
                color: #333;
            }
        }

        .magemenu-menu {
            ul.explodedmenu {
                >li.menu {
                    position: relative;
                    display: block;
                    width: 100%;
                    float: none;

                    .menu-collapse {
                        &:before {
                            content: '\f0d7';
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-weight: bold;
                            font-size: 14px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 2;
                            width: 45px;
                            height: 50px;
                            line-height: 30px;
                            padding: 10px 15px 10px 10px;
                            text-align: center;
                        }
                    }

                    >a {
                        float: none;
                        padding: 15px;
                        font-weight: bold;
                    }

                    >div {
                        position: static;
                        visibility: visible;
                        display: none;

                        >div.menu-box {
                            margin: 0;
                            padding: 15px 15px 45px;
                            visibility: visible;
                            filter: alpha(opacity=100);
                            -moz-opacity: 1;
                            opacity: 1;
                            background: none;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                            transform: scale(1);
                        }
                    }

                    &.active {
                        .menu-collapse {
                            &:before {
                                content: '\f0d8';
                            }
                        }

                        >div {
                            display: block;
                        }
                    }
                }
            }

            ul.itemsubmenu {
                width: 100%;
                visibility: visible;
                filter: alpha(opacity=100);
                -moz-opacity: 1;
                opacity: 1;
                background: none;

                li {
                    background: none !important;
                    border: none;
                    min-height: auto;

                    >.submenu-container {
                        padding: 0 15px;
                        display: block;
                        position: static;
                    }

                    a {
                        padding: 3px 0;
                    }
                }

            }
        }

        /* END Common */
        /*--Tabs--*/
        ul.itemsubmenu.subtabs {
            padding: 15px 0;

            >li.itemMenu {
                position: relative !important;
                width: 100%;

                &:before {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    right: 0;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    padding-right: 6px;
                    text-align: center;
                    -webkit-box-sizing: content-box;
                    -moz-box-sizing: content-box;
                    box-sizing: content-box;
                    content: '\e61c';
                    font-family: 'luma-icons';
                    font-size: 11px;
                }

                &.active {
                    &:before {
                        content: '\e60f';
                    }
                }

                >a {
                    padding: 8px 15px;
                }

                .tab-menu-content {
                    position: relative;
                    width: 100%;
                    float: none;
                    clear: both;
                }
            }
        }

        .magemenu-menu {

            /* Horizontal */
            &.horizontal-menu {
                ul.explodedmenu {
                    >li.menu {
                        padding: 0;

                        >a {
                            float: none;
                            padding: 15px;
                            margin: 0;

                            span {
                                font-weight: bold !important;
                            }
                        }
                    }
                }
            }


        }

    }
}
