[type="checkbox"],
[type="radio"] {
    visibility: hidden;
    display: none;

    & +.label,
    & +label,
    & + a,
    & ~ .label-extended {
        margin: 0 !important;
        position: relative;
        padding-left: 25px;
        cursor: pointer;


        @include lib-typography(
            $_font-size   : $form-field-label__font-size,
            $_color       : $form-field-label__color,
            $_font-family : $form-field-label__font-family,
            $_font-weight : $form-field-label__font-weight,
            $_line-height : $form-field-label__line-height,
            $_font-style  : $form-field-label__font-style
        );

        &::before {
            content: "";
            width: 14px;
            height: 14px;
            border: 1px solid $text__color;
            position: absolute;
            border-radius: 2px;
            left: 0;
            background: transparent;
            z-index: 1;
            top: 0;
        }
    }

    &.active,
    &:checked {

        & +.label,
        & +label,
        & + a,
        & ~ .label-extended {

            &::before {
                border-color: $text__color;
            }

            &::after {
                content: '' !important;
                z-index: 10;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 3px !important;
                left: 3px !important;
                background: $active__color !important;
                color: $active__color !important;
                margin: 0 !important;
                z-index: 0;
            }
        }
    }
}


[type="radio"] {
    & +.label,
    & +label,
    & + a,
    & ~ .label-extended {
        &::before,
        &::after {
            border-radius: 100%;
        }
    }
}