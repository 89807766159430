.form.wishlist.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @extend .abs-action-addto-product;
    }
}

.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol(
                $icon-wishlist-empty
            );
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: 400;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin-top: 0;
            }

            .price-box {
                margin: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .box-tocart {
                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid.wishlist {
        .product-item {
            border-bottom: 1px solid $secondary__color;
            &:first-child {
                border-top: 1px solid $secondary__color;
            }
        }
    }
}

@include max-screen($screen__m) {
    .products-grid.wishlist {
        margin-bottom: $indent__l;
        margin-right: -$indent__s;
        .product {
            &-item {
                padding: $indent__base $indent__s $indent__base $indent__base;
                position: relative;

                &-photo {
                    float: left;
                    margin-right: $indent__base;
                }

                &-name {
                    @include lib-font-size(16);
                }

                &-actions {
                    display: block;

                    .action {
                        margin-right: 15px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.edit {
                            float: left;
                        }

                        &.delete {
                            float: right;
                        }

                        &.edit,
                        &.delete {
                            margin-top: 7px;
                        }
                    }
                }

                &-name,
                &-description,
                .price-box,
                &-tooltip {
                    margin-left: 95px;
                }

                .box-tocart {
                    float: left;
                    margin-right: $indent__base;

                    .stock {
                        margin-top: 7px;
                    }
                }

                .giftregisty-dropdown,
                .field.qty {
                    display: none;
                }
            }

            &-image-container {
                max-width: 80px;
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                width: 100%;
                &-info {
                    width: auto;
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
            }

            .product-item-actions {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset {
                    .field.qty {
                        margin-bottom: $indent__s;
                        padding-right: $indent__s;

                        .label {
                            width: auto;
                        }
                    }
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .product {
            &-item {
                &-info {
                    width: 240px;
                }
            }
        }

        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 24.439%) / 3);
                padding: 0;
                width: 24.439%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
