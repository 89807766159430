//
//  Variables
//  _____________________________________________

$autocomplete__background-color : $color-white !default;
$autocomplete__border : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    margin-bottom: 0;
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font($_icon-font-content : $icon-search,
            $_icon-font-size : 35px,
            $_icon-font-line-height : 33px,
            $_icon-font-color : $minicart-icons-color,
            $_icon-font-color-hover : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide : true);
        display: inline-block;
        float: right;

        &.active {
            +.control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        border-top: 1px solid $border-color__base;
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0;
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
    }

    .nested {
        display: none;
    }

    @media (max-width: 1200px) {
        width: 170px;
    }
}

#toggle-search {
    display: none;
}

@media (max-width: 992px) {
    #toggle-search {
        display: flex;
        background: none;
        border: 0;
        box-shadow: none;
        margin-left: 15px;

        .fa-times {
            display: none;
        }

        .fa-search {
            display: inline-block;
        }
    }

    .block-search {
        display: none;
        margin-left: auto;
    }

    body.search-open {
        .block-search {
            display: block;
        }

        .header.content .nav-toggle,
        .nav-sections>.section-items {
            display: none !important
        }

        #toggle-search {
            .fa-times {
                display: inline-block
            }

            .fa-search {
                display: none;
            }
        }
    }
}

@media (max-width: 600px) {
    #toggle-search {
        color: #333;
        text-align: right;
        width: 110px;
        justify-content: flex-end;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}


.search.summary {
    margin-bottom: $indent__s;
}


.block-search {

    .label {
        @extend .abs-visually-hidden;
    }

    .control {
        border-top: 0;
        margin: 0;
        padding: 0;
    }

    .nested {
        display: block;
        padding-top: 5px;
        position: absolute;
    }

    input {
        @include lib-input-placeholder($form-element-input-placeholder__color);
        margin: 0;
        padding-right: 35px;
        position: static;
        border: 0;
        border-bottom: 2px solid #f6f5f6;
        height: 40px;
    }

    .action.search {
        display: inline-block;
        @include lib-button-icon($_icon-font-content : $icon-search,
            $_icon-font-text-hide : true,
            $_icon-font-color : $header-icons-color);
        @include lib-button-reset();
        padding: $indent__xs 0;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }
    }
}

.search-autocomplete {
    margin-top: 0;
}
